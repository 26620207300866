'use client';

import { Trans } from '@lingui/macro';
import { DeleteForever } from '@mui/icons-material';
import { Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import { type FC, useCallback, useMemo, useState } from 'react';
type Props = {
  locationId: string;
  onConfirm: () => void;
  disabled?: boolean;
};
export const DeleteButton: FC<Props> = props => {
  const {
    onConfirm: onConfirmAction,
    disabled = false,
    locationId
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => open ? `${locationId}-popover` : undefined, [open, locationId]);
  const onConfirm = useCallback(() => {
    onConfirmAction();
    handleClose();
  }, [onConfirmAction, handleClose]);
  return <>
      <IconButton color="error" size="small" onClick={handleClick} disabled={disabled} data-sentry-element="IconButton" data-sentry-source-file="delete-button.tsx">
        <DeleteForever fontSize="small" data-sentry-element="DeleteForever" data-sentry-source-file="delete-button.tsx" />
      </IconButton>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} keepMounted anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }} data-sentry-element="Popover" data-sentry-source-file="delete-button.tsx">
        <Stack gap={1} padding={1} data-sentry-element="Stack" data-sentry-source-file="delete-button.tsx">
          <Typography fontSize="small" data-sentry-element="Typography" data-sentry-source-file="delete-button.tsx">
            <Trans data-sentry-element="Trans" data-sentry-source-file="delete-button.tsx">Êtes-vous sûr de vouloir supprimer ce commentaire ?</Trans>
          </Typography>
          <Stack gap={2} direction="row" justifyContent="flex-end" alignItems="center" data-sentry-element="Stack" data-sentry-source-file="delete-button.tsx">
            <Button type="button" variant="outlined" color="gray" onClick={handleClose} size="small" data-sentry-element="Button" data-sentry-source-file="delete-button.tsx">
              <Trans data-sentry-element="Trans" data-sentry-source-file="delete-button.tsx">Annuler</Trans>
            </Button>
            <Button type="button" variant="outlined" color="error" onClick={onConfirm} size="small" data-sentry-element="Button" data-sentry-source-file="delete-button.tsx">
              <Trans data-sentry-element="Trans" data-sentry-source-file="delete-button.tsx">Confirmer</Trans>
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>;
};