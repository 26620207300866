'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, t } from '@lingui/macro';
import { Button, Stack } from '@mui/material';
import { TextFieldInput } from '@prismo-io/design-system';
import { type FC, useCallback, useMemo, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { DeleteButton } from './delete-button';
const CommentSchema = z.object({
  content: z.string().trim().min(1)
});
type CommentSchemaT = z.infer<typeof CommentSchema>;
type Props = {
  locationId: string;
  content?: string;
  onUpsert: (params: CommentSchemaT) => unknown;
  onDelete: () => unknown;
};
export const CommentForm: FC<Props> = props => {
  const {
    locationId,
    onUpsert,
    onDelete: onDeleteAction,
    content = undefined
  } = props;
  const [isInitWithContent, setIsInitWithContent] = useState<boolean>(!!content);
  const {
    control,
    handleSubmit,
    reset,
    formState: {
      isValid,
      isSubmitting,
      isSubmitSuccessful,
      isDirty
    }
  } = useForm<CommentSchemaT>({
    mode: 'all',
    resolver: zodResolver(CommentSchema),
    defaultValues: {
      content: content ?? ''
    }
  });
  const onDelete = useCallback(() => {
    onDeleteAction();
    reset({
      content: ''
    });
    setIsInitWithContent(false);
  }, [onDeleteAction, reset]);
  const onSubmit: SubmitHandler<CommentSchemaT> = useCallback(async data => {
    const result = await onUpsert(data);
    setIsInitWithContent(true);
    reset(data);
    return result;
  }, [onUpsert, setIsInitWithContent, reset]);
  const canBeDeleted = useMemo(() => !isSubmitting && isValid && isInitWithContent, [isSubmitting, isValid, isInitWithContent]);
  return <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={2} paddingX={1} paddingTop={2} paddingBottom={1} minWidth={300} data-sentry-element="Stack" data-sentry-component="CommentForm" data-sentry-source-file="comment-form.tsx">
      <TextFieldInput control={control} disabled={isSubmitting} name="content" label={t`Commentaire`} autoComplete="off" size="small" multiline minRows={4} maxRows={10} required data-sentry-element="TextFieldInput" data-sentry-source-file="comment-form.tsx" />
      <Stack direction="row" justifyContent="space-between" alignItems="center" data-sentry-element="Stack" data-sentry-source-file="comment-form.tsx">
        <DeleteButton locationId={locationId} onConfirm={onDelete} disabled={!canBeDeleted} data-sentry-element="DeleteButton" data-sentry-source-file="comment-form.tsx" />
        <Button type="submit" size="small" disabled={!isValid || isSubmitting || !isDirty} data-sentry-element="Button" data-sentry-source-file="comment-form.tsx">
          <Trans data-sentry-element="Trans" data-sentry-source-file="comment-form.tsx">Commenter</Trans>
        </Button>
      </Stack>
    </Stack>;
};